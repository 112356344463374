import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputEmoji from "react-input-emoji";

interface OptionPropsAr {
    name: string;
    description: string;
    emoji: string;
}



interface OptionProps {
    handleOptionNameChange: (name: string, id: number) => void;
    handleOptionDescriptionChange: (description: string, id: number) => void;
    handleOptionEmojiChange: (emoji: string, id: number) => void;
    deleteOption: (id: number) => void;
    id: number;
    title: string;
    // children: React.ReactNode;
    option: OptionPropsAr
}

const Option: React.FC<OptionProps> = ({ title, option, id, handleOptionNameChange, handleOptionDescriptionChange, handleOptionEmojiChange, deleteOption }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const [emoji, set_emoji] = useState(option.emoji)
    const [name, set_name] = useState(option.name)
    const [description, set_description] = useState(option.description)

    const [error, set_error] = useState("")

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const nameChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        handleOptionNameChange(value, id);
    }
    const descriptionChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        handleOptionDescriptionChange(value, id);
    }
    const emojiChange = (id: number) => {

        const allCharsArray = [
            ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)), // Lowercase letters
            ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)), // Uppercase letters
            ...Array.from({ length: 10 }, (_, i) => String.fromCharCode(48 + i)), // Digits
            "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", // Special characters
        ];
        if (allCharsArray.includes(emoji)) {

            set_error("Only emojis are allowed!")
        }
        else {
            set_error("")
            handleOptionEmojiChange(emoji, id);
        }
    }
    useEffect(() => {
        if (option.emoji !== "" && emoji === "" && option.emoji === "undefined") {
            set_emoji(option.emoji)
        }
    }, [])

    React.useEffect(() => {
        if (option.emoji !== "" && emoji === "" && option.emoji === "undefined") {
            set_emoji(option.emoji)
        }
        const allCharsArray = [
            ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)), // Lowercase letters
            ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)), // Uppercase letters
            ...Array.from({ length: 10 }, (_, i) => String.fromCharCode(48 + i)), // Digits
            "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", // Special characters
        ];
        if (allCharsArray.includes(emoji)) {
            set_error("Only emojis are allowed!")
        }
        else {
            set_error("")
            if (emoji !== "") {
                handleOptionEmojiChange(emoji, id);

            }
        }
    }, [emoji]);


    return (
        <div className="collapsible rounded option_edit">
            <div className="collapsible-header unselectable text-l relative" onClick={toggleCollapse}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <span className={`collapsible-icon ${isCollapsed ? 'open' : 'closed'}`}>
                            <ExpandMoreIcon style={{ color: '#7d7f8b' }} />
                        </span>
                        {title}
                    </div>
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2 option_add">
                        <button onClick={() => { deleteOption(id) }}>
                            X
                        </button>

                    </div>
                </div>
            </div>
            {!isCollapsed && <div className="collapsible-content">
                {/* {children} */}
                <div className='flex'>
                    <div className="mr-1 w-full">
                        <label htmlFor="option_name" className="base_input_text option_name">Name</label>
                        <input
                            type="text"
                            maxLength={255}
                            className="base_input"
                            defaultValue={option.name}
                            onChange={(e) => nameChange(id, e)}
                            placeholder="Your Option Name"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="emoji" className="base_input_text">Emoji</label>
                        <div className='emoji_picker'>
                            <div>
                                <InputEmoji
                                    placeholder=""
                                    value={option.emoji}
                                    onChange={set_emoji}
                                    maxLength={1}
                                />
                            </div>
                            {error !== "" && <p className='text-red-500'>{error}</p>}
                        </div>
                    </div>
                </div>

                <div>
                    <label htmlFor="option_description"
                        className="base_input_text">Description</label>
                    <input
                        maxLength={255}
                        className="base_input"
                        defaultValue={option.description}
                        onChange={(e) => descriptionChange(id, e)}
                        placeholder="Your Description" required />
                </div>
            </div>}
        </div>
    );
};

export default Option;
