import React, { useState } from 'react';
import axios from 'axios';
import "../../styles/base_commands_styles.css"
import EditIcon from '@mui/icons-material/Edit';
import ReactDOM from 'react-dom';
import Select, { components } from 'react-select';
import CommandPopUp from '../Other/CommandPopUp';
import { ToastContainer, toast } from 'react-toastify';
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from '@mui/material/Tooltip';

const API_URL = "https://api.solvextools.com"


interface Permission {
    channels?: {
        id: string;
        permission: boolean;
    }[];
    members?: {
        id: string;
        permission: boolean;
    }[];
    roles?: {
        id: string;
        permission: boolean;
        color: number
    }[];
}

interface BaseCommandsProps {
    handleBlur: () => void;
    handleLogOut: () => void;
    app_id: string;
}
interface ServerState {
    icon: string;
    id: string;
    name: string;
}

interface Commands {
    id: string;
    name: string;
    permissions: Permission;
}
interface Roles {
    id: string;
    name: string;
    color: number;
}
interface Members {
    id: string;
    name: string;
    avatar: string;
}
interface Channels {
    id: string;
    name: string;
}

const OptionWithImage = (props: any) => {
    return (
        <components.Option {...props}>
            <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.Option>
    );
};
const SingleValueWithImage = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <img src={props.data.icon} alt={props.data.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.SingleValue>
    );
};
const customStyles_server = {
    input: (provided: any) => ({
        ...provided,
        color: "white",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '170px', // Change border color
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px', // Change font size
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white', // Add text color
        '&:active': {
            backgroundColor: '#f2f2f2', // Add background color for active state
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd', // Change background color on hover
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },

    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),

};

const BaseCommands: React.FC<BaseCommandsProps> = ({ handleBlur, handleLogOut, app_id }) => {
    const [get_data, set_get_data] = React.useState(false);
    const [commands, set_commands] = useState<Commands[]>([]);

    const [roles, set_roles] = useState<Roles[]>([]);
    const [members, set_members] = useState<Members[]>([]);
    const [channels, set_channels] = useState<Channels[]>([]);

    const [current_command, set_current_command] = useState<Commands>({
        id: "",
        name: "",
        permissions: {}
    });
    const [popup, set_popup] = useState(false);
    const [scrape_cmd, set_scrape_cmd] = useState(false);
    const [search_term, set_search_term] = useState<string>('');
    const [guilds, set_guilds] = useState([])
    const [server, set_server] = useState<ServerState | null>(null);
    const [server_id, set_server_id] = useState("");
    const [guilds_scraped, set_guilds_scraped] = useState(false);





    const get_commands = async () => {
        try {
            if (server !== null) {

                const response = await axios.get(`${API_URL}/v1/user/info/bot/commands?guild_id=${server.id}`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });
                set_server_id(server.id);


                set_commands(response.data.data.bots[0].commands)
            }
        } catch (error) {
            console.error('Error fetching commands', error);
        }
    };
    const show_editor = async (command) => {
        set_popup(true);
        handleBlur();
        set_scrape_cmd(!scrape_cmd);
        set_current_command(command);
    }
    const get_bot_guilds = async () => {
        try {


            const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                set_guilds(response.data.data.bots[0].guilds)

            }
        } catch (error) {

            handleLogOut();
        }

    }
    const get_guild_roles = async () => {
        try {

            if (server !== null) {
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${server.id}/roles`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    set_roles(response.data.data.bots[0].roles)

                }
            }
        } catch (error) {

            handleLogOut();
        }

    }
    const get_guild_channels = async () => {
        try {

            if (server !== null) {
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${server.id}/channels`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    set_channels(response.data.data.bots[0].channels)

                }
            }
        } catch (error) {

            handleLogOut();
        }

    }
    const get_guild_members = async () => {
        try {

            if (server !== null) {
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds/${server.id}/members`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    set_members(response.data.data.bots[0].members)

                }
            }
        } catch (error) {

            handleLogOut();
        }

    }
    const handle_change_server = (selectedOption: any) => {
        set_server(selectedOption);
    };
    const filtered_commands = commands.filter(command =>
        command.name.toLowerCase().includes(search_term.toLowerCase())
    );
    const handle_cancel = () => {
        set_popup(false);
        handleBlur();
    }
    const handle_search_change = (e: React.ChangeEvent<HTMLInputElement>) => {
        set_search_term(e.target.value);
    };

    const handle_show_success = () => {
        toast.success('Successfully updated command permissions!', {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
        get_commands();
    }

    const get_auth_2 = async () => {
        try {
            if (server !== null) {


                let response = await axios.get(`${API_URL}/v1/user/oauth/check`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 206) {
                    //const LINK = "https://discord.com/api/oauth2/authorize?client_id=" + app_id + "&redirect_uri=http%3A%2F%2F127.0.0.1%3A3000%2Fcallback&response_type=code&scope=identify%20guilds%20applications.commands.permissions.update&state=" + app_id; //dev
                    const LINK = "https://discord.com/api/oauth2/authorize?client_id=" + app_id + "&redirect_uri=https%3A%2F%2Fdashboard.solvextools.com%2Fcallback&response_type=code&scope=identify%20guilds%20applications.commands.permissions.update&state=" + app_id; //prod
                    window.location.replace(LINK);
                }
            }

        } catch (error) {

            handleLogOut();
        }
    }


    React.useEffect(() => {
        if (get_data) {
            get_bot_guilds();
        }
    }, [get_data]);

    React.useEffect(() => {
        if (guilds.length > 0 && !guilds_scraped) {
            set_server(guilds[0]);
            set_guilds_scraped(true);
        }
    }, [guilds]);

    React.useEffect(() => {
        if (server && server.id !== "" && server.name !== "") {
            get_all_data();
        }
    }, [server]);

    function get_all_data() {
        get_commands();
        get_auth_2();
        get_guild_roles();
        get_guild_members();
        get_guild_channels();
    }



    React.useEffect(() => {
        set_get_data(true)
    }, []);
    return (
        <div>
            <ToastContainer />

            {popup && server && ReactDOM.createPortal(
                <>
                    <CommandPopUp current_command={current_command} handle_close={handle_cancel} roles={roles} members={members} channels={channels} server_id={server_id} app_id={app_id} handle_show_success={handle_show_success} />
                </>, document.body
            )}
            <div className='command-container'>
                <p className="text-xl">Base Commands</p>
                <input
                    type="text"
                    value={search_term}
                    onChange={handle_search_change}
                    placeholder="Search commands..."
                    className='command_search'
                />
                <Select
                    className="server_dropdown mb-2"
                    classNamePrefix="select"
                    value={server}
                    isSearchable={false}
                    options={guilds}
                    styles={customStyles_server}
                    onChange={handle_change_server}
                    components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                    placeholder="Select Server"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#509dfd',
                        },
                    })}

                />
                {
                    filtered_commands.map((command) => (
                        <div className='command'>
                            <p>{command.name}</p>
                            {
                                (
                                    (command.permissions.channels && command.permissions.channels?.length > 0) ||
                                    (command.permissions.roles && command.permissions.roles?.length > 0) ||
                                    (command.permissions.members && command.permissions.members?.length > 0)
                                ) && (
                                    <Tooltip title="Permissions updated from default" placement='top'>
                                        <div className='updated'><UpdateIcon /></div>
                                    </Tooltip>
                                )
                            }


                            <Tooltip title="Update the command permission" placement='top'>
                                <div className='edit_update' onClick={() => show_editor(command)}>
                                    <EditIcon />

                                </div>
                            </Tooltip>
                        </div >

                    ))}
            </div>
        </div>
    )
}

export default BaseCommands;