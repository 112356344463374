import React from "react";

interface GiveawayProps {
    // companyName: string;
}

const Giveaways: React.FC<GiveawayProps> = () => {
    return (

        <div className="scrollable">


            <p>Giveaway</p>

            <div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="first_name" className="base_input_text">First name</label>
                        <input type="text" id="first_name"
                            className="base_input"
                            placeholder="John" required />
                    </div>
                    <div>
                        <label htmlFor="last_name"
                            className="base_input_text">Last name</label>
                        <input type="text" id="last_name"
                            className="base_input"
                            placeholder="Doe" required />
                    </div>
                    <div>
                        <label htmlFor="company" className="base_input_text">Company</label>
                        <input type="text" id="company"
                            className="base_input"
                            placeholder="Flowbite" required />
                    </div>
                    <div>
                        <label htmlFor="phone" className="base_input_text">Phone number</label>
                        <input type="tel" id="phone"
                            className="base_input"
                            placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                    </div>
                    <div>
                        <label htmlFor="website" className="base_input_text">Website URL</label>
                        <input type="url" id="website"
                            className="base_input"
                            placeholder="flowbite.com" required />
                    </div>
                    <div>
                        <label htmlFor="visitors" className="base_input_text">Unique visitors (per month)</label>
                        <input type="number" id="visitors"
                            className="base_input"
                            placeholder="" required />
                    </div>
                </div>
                <div className="mb-6">
                    <label htmlFor="email" className="base_input_text">Email address</label>
                    <input type="email" id="email"
                        className="base_input"
                        placeholder="john.doe@company.com" required />
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="base_input_text">Password</label>
                    <input type="password" id="password"
                        className="base_input"
                        placeholder="•••••••••" required />
                </div>
                <div className="mb-6">
                    <label htmlFor="confirm_password" className="base_input_text">Confirm password</label>
                    <input type="password" id="confirm_password"
                        className="base_input"
                        placeholder="•••••••••" required />
                </div>
                <div className="flex items-start mb-6">
                    <div className="flex items-center h-5">
                        <input id="remember" type="checkbox" value=""
                            className="base_input"
                            required />
                    </div>
                    <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
                </div>
                <button type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Submit
                </button>

            </div>


        </div>

    );
};

export default Giveaways;
