import axios from "axios";
import React, { useState, useEffect } from "react";
import "../../styles/dashboard_access_styles.css"
import ReactDOM from 'react-dom';
import DeleteIcon from '@mui/icons-material/Delete';



const API_URL = "https://api.solvextools.com";


interface AccessProps {
    // companyName: string;
    handleLogOut: () => void;
    handleBlur: () => void;
    app_id: string;
}
interface User {
    discord_id: string;
    icon: string;
    name: string;
    permission: number;
}

const DashboardAccess: React.FC<AccessProps> = ({ handleLogOut, handleBlur, app_id }) => {
    const [scraped, setScraped] = useState(false)
    const [show_popup, set_show_popup] = useState(false)
    const [copy, set_copied] = useState("copy")
    const [error_update, set_error_update] = useState("")


    const [invite_link, set_invite_link] = useState("")

    const [users, set_users] = useState<User[]>([])

    const get_dashboard_users = async () => {
        try {

            const response = await axios.get(`${API_URL}/v1/user/info/bot/users`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {

                //setChannels(response.data.data.bots[0].channels)
                const data = response.data.data.bots[0].users

                set_users(data)


            }
        } catch (error) {
            handleLogOut();
        }
    }
    const get_expiration_date = () => {
        const current_date = new Date();

        const expiration_date = new Date(current_date.getTime() + 7 * 24 * 60 * 60 * 1000);

        const formatted_expiration_date = expiration_date.toLocaleString('en-EU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });
        return formatted_expiration_date;
    }
    const handle_invite_popup = async () => {
        console.log("clicked on new server add")
        set_show_popup(!show_popup)
        if (!show_popup) {
            // fetch link
            try {
                const response = await axios.post(`${API_URL}/v1/create/bot/invite`, {
                    app_id: app_id,
                    permission: 2,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });
                if (response.status === 200) {
                    set_invite_link(response.data.data.link)
                }
                else if (response.status === 203) {
                    set_error_update("ERROR: Not enough permission!")
                }
                else {
                    set_error_update("An error occured!")
                }

            }
            catch {
                // handleLogOut()
                set_error_update("An error occured!")
            }
        }
        else {
            set_invite_link("")
            set_copied("copy")
        }
        handleBlur()
    }
    const handle_copy = async () => {
        try {
            await navigator.clipboard.writeText(invite_link);
            set_copied("copied")
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    const remove_user = async (user) => {
        console.log(user)
        try {
            const response = await axios.post(`${API_URL}/v1/remove/bot/user`, {
                app_id: app_id,
                user_id: user.discord_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                get_dashboard_users()
            }


        }
        catch {
            // handleLogOut()
            set_error_update("An error occured!")
        }

    }
    useEffect(() => {
        setScraped(true)
    }, []);
    useEffect(() => {
        if (scraped) {
            // get data
            get_dashboard_users()

        }
    }, [scraped]);

    const id_to_str = (id) => {
        switch (id) {
            case 1:
                return "Owner"
            case 2:
                return "Admin"
            default:
                return "N/A";
        }
    }



    return (
        <>
            <p className="text-xl">Dashboard Access</p>
            {show_popup && ReactDOM.createPortal(
                <div className="invite_user_popup">
                    <p className="font-bold">Invite user to dashboard</p>
                    <p>URL: <a href={invite_link} className="text-blue-500 underline">{invite_link}</a></p>
                    <p>This link expires at {get_expiration_date()} and is only valid once.</p>

                    <button className="invite_btn" onClick={handle_invite_popup}>Cancel</button>
                    <button className="invite_btn" onClick={handle_copy}>{copy}</button>

                </div >
                , document.body // This is the container where the portal will be rendered
            )}
            <button className='btn_add_user' onClick={handle_invite_popup}>Invite user</button>

            <div>
                {users.map((user) => {
                    return (
                        <div className="user-card" key={user.discord_id}>
                            <img src={user.icon} alt={user.name} className="user-avatar" />
                            <div className="user-info">
                                <h3 className="user-name">{user.name}</h3>
                                <p className="user-permission">{id_to_str(user.permission)}</p>
                            </div>
                            {user.permission !== 1 && (
                                <button className="edit-button" onClick={() => remove_user(user)}><DeleteIcon /></button>
                            )
                            }
                        </div>
                    )
                })}


            </div >
        </>

    );
};

export default DashboardAccess;