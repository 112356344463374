import React, { useState } from 'react';
import "../../styles/command_popup_styles.css"
import Select, { components } from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import XIcon from '@mui/icons-material/Close';
import axios from 'axios';



const API_URL = "https://api.solvextools.com"


interface PermissionData {
    channels?: {
        id: string;
        permission: boolean;
    }[];
    members?: {
        id: string;
        permission: boolean;
    }[];
    roles?: {
        id: string;
        permission: boolean;
    }[];
}

interface Command {
    id: string;
    name: string;
    permissions: PermissionData;
}
interface Roles {
    id: string;
    name: string;
    color: number;
}
interface Members {
    id: string;
    name: string;
    avatar: string;
}
interface Channels {
    id: string;
    name: string;
}



interface ExtendedRoles extends Roles {
    permission: boolean;
}
interface ExtendedMembers extends Members {
    permission: boolean;
}
interface ExtendedChannels extends Channels {
    permission: boolean;
}


interface PopupProps {
    current_command: Command;
    handle_close: () => void;
    handle_show_success: () => void;
    roles: Roles[];
    members: Members[];
    channels: Channels[];
    server_id: string;
    app_id: string;
}

const customStyles = {
    input: (provided: any) => ({
        ...provided,
        color: "white",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#2b2d3e",
        width: '270px', // Change border color
    }),
    menu: (provided: any) => ({
        ...provided,
        fontSize: '14px', // Change font size
        width: "170px",
        backgroundColor: "#2b2d3e",
        color: "white",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        color: 'white', // Add text color
        '&:active': {
            backgroundColor: '#f2f2f2', // Add background color for active state
            color: "black"
        },
        '&:hover': {
            backgroundColor: '#509dfd', // Change background color on hover
            color: "black"
        },
        '&:focus': {
            backgroundColor: '#CCCCCC',
            color: "black"
        },

    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: "white"
    }),

};
const OptionWithImage = (props: any) => {
    return (
        <components.Option {...props}>
            <img src={props.data.avatar} alt={props.data.name} style={{ width: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.Option>
    );
};
const SingleValueWithImage = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <img src={props.data.avatar} alt={props.data.name} style={{ width: '20px', height: '20px', marginRight: '8px', borderRadius: "50%" }} />
            {props.data.name}
        </components.SingleValue>
    );
};



const combineWithPermissionRoles = (selectedItems: Roles[], permissionItems: any[]): ExtendedRoles[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}
const combineWithPermissionChannels = (selectedItems: Channels[], permissionItems: any[]): ExtendedChannels[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}
const combineWithPermissionMembers = (selectedItems: Members[], permissionItems: any[]): ExtendedMembers[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const Popup: React.FC<PopupProps> = ({ current_command, handle_close, roles, members, channels, server_id, app_id, handle_show_success }) => {
    // State for form inputs


    const [selected_roles, set_selected_roles] = useState<Roles[]>([]);

    const [selected_members, set_selected_members] = useState<Members[]>([]);
    const [selected_channels, set_selected_channels] = useState<Channels[]>([]);
    const [show_edit, set_show_edit] = useState(false);
    const [error_update, set_error_update] = useState("");

    const [show_last_step, set_show_last_step] = useState(false);

    const permission = current_command.permissions

    const [selected_roles_new, set_selected_roles_new] = useState<ExtendedRoles[]>(
        combineWithPermissionRoles(selected_roles, permission.roles || [])
    );
    const [selected_members_new, set_selected_members_new] = useState<ExtendedMembers[]>(
        combineWithPermissionMembers(selected_members, permission.members || [])
    );
    const [selected_channels_new, set_selected_channels_new] = useState<ExtendedChannels[]>(
        combineWithPermissionChannels(selected_channels, permission.channels || [])
    );

    const [mode, set_mode] = useState(null);
    const [all_channels, set_all_channels] = useState(null);

    const [btn_disabled, set_btn_disabled] = useState(true);

    React.useEffect(() => {
        set_selected_roles_new(combineWithPermissionRoles(selected_roles, permission.roles || []));
    }, [selected_roles, permission.roles]);

    React.useEffect(() => {
        set_selected_members_new(combineWithPermissionMembers(selected_members, permission.members || []));
    }, [selected_members, permission.members]);

    React.useEffect(() => {
        set_selected_channels_new(combineWithPermissionChannels(selected_channels, permission.channels || []));
    }, [selected_channels, permission.channels]);

    React.useEffect(() => {
        const sum = selected_channels.length + selected_members.length + selected_roles.length
        if (sum > 0) {
            set_btn_disabled(false)
        }
        else {
            set_btn_disabled(true)
        }
    }, [selected_channels, selected_members, selected_roles]);


    // Handler
    const handleModeChange = (selectedOption) => {
        set_mode(selectedOption.value);
    };

    const handleRolePermissionChange = (roleId: string) => {
        set_selected_roles_new(prevState => {
            return prevState.map(role => {
                if (role.id === roleId) {
                    return { ...role, permission: !role.permission };
                }
                return role;
            });
        });
    };
    const handleMemberPermissionChange = (roleId: string) => {
        set_selected_members_new(prevState => {
            return prevState.map(role => {
                if (role.id === roleId) {
                    return { ...role, permission: !role.permission };
                }
                return role;
            });
        });
    };
    const handleChannelPermissionChange = (roleId: string) => {
        set_selected_channels_new(prevState => {
            return prevState.map(role => {
                if (role.id === roleId) {
                    return { ...role, permission: !role.permission };
                }
                return role;
            });
        });
    };

    const handle_selected_members = (selected) => {
        set_selected_members(selected); // Update the selected options in state
    };
    const handle_selected_channels = (selected) => {
        set_selected_channels(selected); // Update the selected options in state
    };
    const handle_selected_roles = (selected) => {
        set_selected_roles(selected); // Update the selected options in state
    };

    // Transform Ids to name
    const updatedData = mapIdsToNames(permission, roles, members, channels);
    const show_success = () => {
        handle_show_success()
        handle_close();
    }



    // Function to handle saving changes
    const handleSave = async () => {

        // Implement logic to save the changes here
        // You can use the state variables to access form inputs
        // Don't forget to call onClose() to close the popup
        try {
            const response = await axios.post(`${API_URL}/v1/update/bot/commands`, {
                selected_channels: selected_channels_new,
                selected_roles: selected_roles_new,
                selected_members: selected_members_new,
                app_id: app_id,
                guild_id: server_id,
                command_id: current_command.id,

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                show_success()
            }
            else if (response.status === 203) {
                set_error_update("ERROR: Not enough permission!")
            }
            else {
                set_error_update("An error occured!")
            }

        }
        catch {
            // handleLogOut()
            set_error_update("An error occured!")
        }
        // handle_close();
    };

    // Add Labels for Select Form
    const roleWithLabel = roles.map((role) => ({
        ...role,
        value: role.id,
        label: role.name,
    }));
    const membersWithLabel = members.map((member) => ({
        ...member,
        label: member.name,
        value: member.id,
    }));
    const channelsWithLabel = channels.map((channel) => ({
        ...channel,
        label: channel.name,
        value: channel.id,
    }));





    const mode_options = [
        {
            "label": "Channel",
            "value": "channel"
        },
        {
            "label": "Role",
            "value": "role"
        },
        {
            "label": "Member",
            "value": "member"
        }
    ]


    // navigation
    const handle_back_menu = () => {
        clear_ar();
        set_show_edit(false);
    }
    const handle_back_edit = () => {
        set_show_edit(true);
        set_show_last_step(false);
    }
    const handle_last_step = () => {
        set_show_edit(false);
        set_show_last_step(true);
    }
    const change_edit = () => {
        set_show_edit(!show_edit);
        loop_through_data();

    }

    // utils
    const getPermissionSymbol = (permission) => {
        return permission ? <CheckIcon className="white-icon" /> : <XIcon className="white-icon" />;
    };
    const loop_through_data = () => {
        if (permission.channels && permission.members && permission.roles) {
            permission.members.forEach((member) => {

                const matchedMember = members.find((item) => item.id === member.id);

                if (matchedMember) {
                    const member_new = {
                        ...matchedMember,
                        label: matchedMember.name,
                        value: matchedMember.id,
                    };
                    set_selected_members((prevMembers) => [...prevMembers, member_new]);
                }
            });

            permission.roles.forEach((role) => {
                const matchedRole = roles.find((item) => item.id === role.id);
                if (matchedRole) {
                    const role_new = {
                        ...matchedRole,
                        label: matchedRole.name,
                        value: matchedRole.id,
                        color: matchedRole.color,
                    };
                    set_selected_roles((prevRoles) => [...prevRoles, role_new]);
                }
            });

            permission.channels.forEach((channel) => {
                const matchedChannel = channels.find((item) => item.id.toString() === channel.id.toString());

                if (matchedChannel) {
                    const channel_new = {
                        ...matchedChannel,
                        label: matchedChannel.name,
                        value: matchedChannel.id,
                    };
                    set_selected_channels((prevChannels) => [...prevChannels, channel_new]);
                }

            });
        }
    };
    function mapIdsToNames(data: PermissionData, roles: Roles[], members: Members[], channels: Channels[]) {


        const idToNameMap = new Map<string, string>();

        roles.forEach((role) => {
            idToNameMap.set(role.id, role.name);
        });

        members.forEach((member) => {
            idToNameMap.set(member.id, member.name);
        });

        channels.forEach((channel) => {
            idToNameMap.set(channel.id, channel.name);
        });

        // Replace IDs with names in the data object
        if (permission.channels && permission.members && permission.roles) {
            const updatedData = {
                ...data,
                channels: permission.channels.map((channel) => ({
                    //id: parseInt(channel.id) === server_id - 1 ? 'all channels' : (idToNameMap.get(channel.id) || channel.id),
                    id: idToNameMap.get(channel.id) || channel.id,


                    permission: channel.permission,
                })),
                members: permission.members.map((member) => ({
                    id: idToNameMap.get(member.id) || member.id,
                    permission: member.permission,
                })),
                roles: permission.roles.map((role) => ({
                    id: idToNameMap.get(role.id) || role.id,
                    permission: role.permission,
                })),
            };
            return updatedData;
        }
    }
    const clear_ar = () => {
        set_selected_channels([]);
        set_selected_roles([]);
        set_selected_members([]);
    }

    return (
        <div className='edit-popup'>
            <p>{current_command.name}</p>
            {/* displays the menus for updating permissions */}
            {show_edit && (
                <div>
                    <div className='close-perms-popup' onClick={handle_close}>x</div>
                    <div className='popup-row'>
                        <div className="popup-content-columns">



                            <div className="section">
                                <div>
                                    <label>Mode</label>
                                    <Select
                                        classNamePrefix="mode"
                                        options={mode_options}
                                        styles={customStyles}
                                        value={mode}
                                        onChange={handleModeChange}
                                        isSearchable={false}

                                        // onChange={handle_change_server}
                                        placeholder="Select Mode"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#509dfd',
                                            },
                                        })}
                                    />
                                </div>



                                {mode === "channel" && (
                                    <div>
                                        <label>Channels:</label>
                                        <Select
                                            classNamePrefix="channel"
                                            isSearchable={true}
                                            styles={customStyles}
                                            onChange={handle_selected_channels}
                                            options={channelsWithLabel}
                                            value={selected_channels}
                                            isMulti
                                            placeholder="Select Channel"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                                {mode === "role" && (
                                    < div >
                                        <label>Roles:</label>
                                        <Select
                                            classNamePrefix="role"
                                            isSearchable={true}
                                            options={roleWithLabel}
                                            styles={customStyles}
                                            value={selected_roles}
                                            onChange={handle_selected_roles}
                                            isMulti
                                            placeholder="Select Role"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                                {mode === "member" && (
                                    <div>
                                        <label>Members:</label>
                                        <Select
                                            classNamePrefix="member"
                                            isSearchable={true}
                                            styles={customStyles}
                                            options={membersWithLabel}
                                            components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                                            onChange={handle_selected_members}
                                            value={selected_members}
                                            isMulti
                                            placeholder="Select Member"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#509dfd',
                                                },
                                            })}
                                        />
                                    </div>
                                )}

                            </div>




                        </div>

                        <div className='popup-contentcolumns'>
                            <div className="selection-container">
                                <p className='selection-title'>Selections</p>
                                {selected_channels.length > 0 && (
                                    <div>

                                        <div className="selection-group">
                                            <p className='group-title'>Channels</p>
                                            {selected_channels.map((channel) => (
                                                <p className="item-name">{channel.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}


                                {selected_roles.length > 0 && (
                                    <div>
                                        <div className="selection-group">
                                            <p className='group-title'>Roles</p>
                                            {selected_roles.map((role) => (
                                                <p className="item-name">{role.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selected_members.length > 0 && (
                                    <div>
                                        <div className="selection-group">
                                            <p className='group-title'>Members</p>
                                            {selected_members.map((member) => (
                                                <p className="item-name">{member.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selected_members.length === 0 && selected_roles.length === 0 && selected_channels.length === 0 && (
                                    <div>
                                        <p>Please pick at least one permission!</p>
                                    </div>

                                )}
                            </div>

                        </div>
                    </div>

                    <div className="button-container">
                        <button className="base_btn" onClick={handle_back_menu}>Back</button>
                        <button className="base_btn" onClick={handle_last_step} disabled={btn_disabled}>Next Step</button>
                    </div>
                </div>
            )}
            {!show_edit && !show_last_step && (
                <div className='centered-perms'>
                    <div className='close-perms-popup' onClick={handle_close}>x</div>

                    <h2 className="permissions-header">Current Permissions:</h2>
                    <div className="permissions-container">
                        {updatedData?.channels && updatedData?.channels.length > 0 && (
                            <div className="permissions-section">

                                <h3 className="section-header">Channels</h3>

                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.channels.map((channel) => (
                                            <tr key={channel.id}>
                                                <td>{channel.id}</td>
                                                <td>{getPermissionSymbol(channel.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {updatedData?.members && updatedData?.members.length > 0 && (
                            <div className="permissions-section">

                                <h3 className="section-header">Members</h3>

                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.members.map((member) => (
                                            <tr key={member.id}>
                                                <td>{member.id}</td>
                                                <td>{getPermissionSymbol(member.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {updatedData?.roles && updatedData?.roles.length > 0 && (
                            <div className="permissions-section">

                                <h3 className="section-header">Roles</h3>

                                <table className="permissions-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {updatedData.roles.map((role) => (
                                            <tr key={role.id}>
                                                <td>{role.id}</td>
                                                <td>{getPermissionSymbol(role.permission)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {!updatedData && (
                            <div>No customized permissions found!</div>
                        )}

                    </div>
                    <button className="base_btn" onClick={handle_close}>Cancel</button>
                    <button className='base_btn' onClick={change_edit}>Edit permissions</button>


                </div>
            )}
            {!show_edit && show_last_step && (
                <div className="permissions-popup">

                    <div className='close-perms-popup' onClick={handle_close}>x</div>

                    <div className="permissions-content">

                        {/* Roles */}
                        {selected_roles_new.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Roles</div>
                                {selected_roles_new.map(role => (
                                    <div key={role.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="role-checkbox"
                                            checked={role.permission}
                                            onChange={() => handleRolePermissionChange(role.id)}
                                        />
                                        <span>{role.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Channels */}
                        {selected_channels_new.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Channels</div>
                                {selected_channels_new.map(channel => (
                                    <div key={channel.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="channel-checkbox"
                                            checked={channel.permission}
                                            onChange={() => handleChannelPermissionChange(channel.id)}
                                        />
                                        <span>{channel.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Members */}
                        {selected_members_new.length > 0 && (
                            <div className="permissions-category">
                                <div className="category-title">Members</div>
                                {selected_members_new.map(member => (
                                    <div key={member.id} className="item-container">
                                        <input
                                            type="checkbox"
                                            className="member-checkbox"
                                            checked={member.permission}
                                            onChange={() => handleMemberPermissionChange(member.id)}
                                        />
                                        <span>{member.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>

                    <div className='button-container'>
                        <button className="base_btn" onClick={handle_back_edit}>Back</button>
                        <button className="base_btn" onClick={handleSave}>Save</button>
                        <p className='error_update'>{error_update}</p>
                    </div>

                </div>


            )}

        </div >
    );
};

export default Popup;
