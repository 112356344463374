import React from "react";
import "../../styles/payment_styles.css"
import axios from 'axios';


interface PaymentProps {
    nextPaymentDate: string;
    handleLogOut: () => void;
}

interface PaymentInt {
    date: string;
    price: string;
    description: string;
}


const API_URL = "https://api.solvextools.com"


const Payment: React.FC<PaymentProps> = ({ nextPaymentDate, handleLogOut }) => {
    const [getData, setGetData] = React.useState(false);
    const [lastPayments, setLastPayments] = React.useState<PaymentInt[]>([]);

    const get_payment_data = async () => {
        try {


            const response = await axios.get(`${API_URL}/v1/user/info/bot/payments`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {

                const payments: PaymentInt[] = [];
                for (const bot of response.data.data.bots) {
                    if (bot.payments && Array.isArray(bot.payments)) {
                        for (const payment of bot.payments) {
                            payments.push(payment);
                        }
                    }
                }
                setLastPayments(payments);

            }
        } catch (error) {
            console.log(error)
            // handleLogOut();
        }

    }
    React.useEffect(() => {
        if (getData) {
            get_payment_data()
        }
    }, [getData]);

    React.useEffect(() => {
        setGetData(true)
    }, []);


    return (
        <div className="center-container">

            <div className="payment-container">
                <p className="payment-title">Next payment:</p>
                <p className="next_date">{nextPaymentDate}</p>
                <div>
                    <p className="payment-title">Last Payments</p>
                    <div className="payment-header">
                        <span className="payment-date-header">Date</span>
                        <span className="payment-description-header">Description</span>
                        <span className="payment-price-header">Price</span>
                    </div>

                    <ul className="payment-list">
                        {lastPayments.length > 0 ? (
                            lastPayments.map((payment: PaymentInt, index) => (
                                <li key={index} className="payment-list-item">
                                    <span className="payment-date">{payment.date.split('.')[0]}</span>
                                    <span className="payment-description">{payment.description}</span>
                                    <span className="payment-price">{payment.price}</span>
                                </li>
                            ))
                        ) : (
                            <p>No last payments available.</p>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Payment;
