import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface FieldPropsAr {
    name: string;
    value: string;
    inline: boolean;
}

interface FieldProps {
    title: string;
    id: number;
    handleFieldNameChange: (name: string, id: number) => void;
    handleFieldValueChange: (value: string, id: number) => void;
    handleInlineChange: (value: boolean, id: number) => void;
    deleteField: (id: number) => void;
    fields: FieldPropsAr[]
}

const Field: React.FC<FieldProps> = ({ title, id, handleFieldValueChange, handleFieldNameChange, fields, deleteField, handleInlineChange }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const nameChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        handleFieldNameChange(value, id);
    }
    const inline_change = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        handleInlineChange(value, id);
    }
    const valueChange = (id: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        handleFieldValueChange(value, id);
    }

    return (
        <div className="collapsible rounded field_edit" key={id}>
            <div className="collapsible-header unselectable text-l relative" onClick={toggleCollapse}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <span className={`collapsible-icon ${isCollapsed ? 'open' : 'closed'}`}>
                            <ExpandMoreIcon style={{ color: '#7d7f8b' }} />
                        </span>
                        {title}

                    </div>
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2 field_add">
                        <button onClick={() => deleteField(id)}>X</button>
                    </div>

                </div>
            </div>
            {!isCollapsed && <div className="collapsible-content">
                <div className='flex'>
                    <div className="mr-1 w-full">
                        <label htmlFor="field_name" className="base_input_text field_name">Field Name</label>
                        <input
                            type="text"
                            maxLength={255}
                            className="base_input"
                            defaultValue={fields[id].name}
                            onChange={(e) => nameChange(id, e)}
                            placeholder="Your Field Name"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="field_inline" className="base_input_text">Inline</label>
                        <input type="checkbox" className='field_inline mt-1 w-full' defaultChecked={fields[id].inline} onChange={(e) => inline_change(id, e)} />
                    </div>
                </div>



                <div>
                    <label htmlFor="field_value"
                        className="base_input_text">Field Value</label>
                    <textarea
                        maxLength={255}
                        className="base_input"
                        defaultValue={fields[id].value}
                        onChange={(e) => valueChange(id, e)}
                        placeholder="Your Text" required />
                </div>
            </div>}
        </div>
    );
};

export default Field;
