import React from 'react';
import Select from 'react-select';





interface OptionProps {
    name: string;
    description: string;
    emoji: string;
}
interface DropdownProps {
    options: OptionProps[];
    placeholder: String;
}

const DiscordDropdown: React.FC<DropdownProps> = ({ options, placeholder }) => {
    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: "white",
            width: "100%"

        }),
        singleValue: (provided: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: "white"
        }),
        control: (provided: any) => ({
            ...provided,
            // width: "400px",
            backgroundColor: "#201c24",
            boxShadow: 'none',
            borderColor: "black",
            '&:hover': {

                borderColor: "black",
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            width: "100%",
            backgroundColor: "#38343c"
        }),
    };

    const formatOptionLabel = ({ name, description, emoji }: OptionProps) => (
        <div className="flex items-center">
            <span className="mr-2 emoji">{emoji}</span>
            <div>
                <strong>{name}</strong>
                <div className="text-gray-500 text-sm">{description}</div>
            </div>
        </div>
    );

    const selectOptions = options.map(option => ({
        value: option.name,
        label: option.name,
        ...option
    }));
    const isOptionDisabled = (option: OptionProps) => {
        return true;
    };

    return (
        <div className="discord-dropdown-container" style={{ position: 'absolute', bottom: '-40px', left: "-5px", width: "100%" }}>

            <Select
                options={selectOptions}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
                placeholder={placeholder}
                isSearchable={false}
                isOptionDisabled={isOptionDisabled}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#38343c',
                    },
                })}
            />
        </div>
    );
};

export default DiscordDropdown;