import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/loading_page.css";
import { dark } from "@mui/material/styles/createPalette";


const API_URL = "https://api.solvextools.com"



const About: React.FC = () => {
    const [requestSent, setRequestSent] = useState(true);

    useEffect(() => {
        setRequestSent(false);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!requestSent) {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const code = params.get('code');
                    const app_id = params.get('state');

                    const value = localStorage.getItem("token");
                    const headers = {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        "Authorization": value
                    }

                    if (value && value !== '') {
                        headers["Authorization"] = value;
                    }



                    const response = await axios.post(API_URL + '/v1/user/oauth/discord', {
                        code: code,
                        app_id: app_id
                    }, {
                        headers: headers
                    });

                    if (!response.data.error) {
                        console.log("setting data again")
                        console.log(response.data)
                        console.log(response.data.data)
                        setRequestSent(true);

                        const token = response.data.data.JWT;

                        localStorage.setItem("token", token);

                        // Redirect to Dashboard page
                        if (response.data.data.type !== "commands") {
                            const discord_name = response.data.data.name;
                            const discord_image = response.data.data.image;

                            localStorage.setItem("name", discord_name);
                            localStorage.setItem("image", discord_image);

                            window.location.href = '/settings';

                        }
                        else {
                            window.location.href = '/commands';
                        }
                    }
                } catch (error) {
                    window.location.href = "http://dashboard.solvextools.com/";
                }
            };
        }
        fetchData()

    }, [requestSent]);

    return (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    );
};

export default About;
