import React, { useState, useEffect, useRef } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axios from "axios";
import "../../styles/ticket_logs_styles.css"
import { Save } from "@mui/icons-material";



const API_URL = "https://api.solvextools.com";

interface LogsProps {
    // companyName: string;
    handleLogOut: () => void;

}
interface AllTicket {
    channel_id: string;
    creation_date: string;
    guild_id: string;
    guild_name: string;
    channel_name: string;
    user_name: string;
    user_discord_id: string;
    id: number;
    status: string
}
interface UserInfo {
    avatar: string;
    name: string;
}

interface Message {
    content: string;
    timestamp: string;
    user_id: number;
}

interface TicketLog {
    channel_id: string;
    transcript: Message[];
    user_info: { [key: string]: UserInfo };
}

const Tickets: React.FC<LogsProps> = ({ handleLogOut }) => {
    const [scraped, setScraped] = useState(false);
    const [tickets, set_tickets] = useState<AllTicket[]>([]);
    const [view, set_view] = useState("ticket_table");
    const [ticket_name, set_ticket_name] = useState("");
    const [ticket, set_current_ticket] = useState<AllTicket>()
    const [ticket_log, set_ticket_log] = useState<TicketLog>();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [unlock_btn, set_unlock_btn] = useState(true);




    const get_tickets = async () => {
        try {

            const response = await axios.get(`${API_URL}/v1/user/info/bot/tickets`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {

                //setChannels(response.data.data.bots[0].channels)
                const data = response.data.data.bots[0].tickets

                set_tickets(data)


            }
        } catch (error) {
            handleLogOut();
        }
    }
    const handle_log = async (ticket) => {
        // get log
        console.log(ticket)
        set_current_ticket(ticket)
        try {

            const response = await axios.get(`${API_URL}/v1/user/info/bot/tickets/log?channel_id=${ticket.channel_id}`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {

                //setChannels(response.data.data.bots[0].channels)
                const data = response.data.data.ticket
                console.log(data)
                set_ticket_log(data)
                if (scrollContainerRef.current) {
                    scrollContainerRef.current.scrollTop = 0;
                }


            }
        } catch (error) {
            handleLogOut();
        }
        set_view("ticket_log")






    }
    const handle_edit = (ticket) => {
        console.log(ticket)
        set_current_ticket(ticket)
        set_view("ticket_edit")

    }
    const go_back = () => {
        set_view("ticket_table")
    }
    const get_user_date = (date_old) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Convert the date string to a Date object
        const date = new Date(date_old);

        // Format the date in a readable format
        const formattedDate = date.toLocaleString('en-GB', { timeZone: userTimeZone });

        return formattedDate;

    }
    const show_success = () => toast.success('Successfully updated ticket!', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const show_error = () => toast.error('An error occurred while updating the ticket! Check input!', {
        position: "top-right",
        autoClose: 10000, // 10 seconds before auto-close
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const show_success_close = () => toast.success('Successfully closed ticket!', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const show_error_close = () => toast.error('An error occurred while closing the ticket!', {
        position: "top-right",
        autoClose: 10000, // 10 seconds before auto-close
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const close_ticket = async () => {
        // close ticket with EP
        try {
            const response = await axios.post(`${API_URL}/v1/close/bot/ticket`, {
                channel_id: ticket?.channel_id,

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                console.log("Updated ticket name!")
                show_success_close()
                get_tickets()

            }
            else if (response.status === 206) {
                console.log("Not updated ticket name!")
                show_error_close()
            }
        }
        catch {
            show_error_close()
            handleLogOut()
        }

    }
    const handle_ticket_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_ticket_name(event.target.value);
        set_unlock_btn(false)
    };
    const update_ticket = async () => {
        // close ticket with EP
        try {
            const response = await axios.post(`${API_URL}/v1/update/bot/ticket/channel`, {
                name: ticket_name,
                channel_id: ticket?.channel_id,

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                console.log("Updated ticket name!")
                show_success()
                get_tickets()

            }
            else if (response.status === 206) {
                console.log("Not updated ticket name!")
                show_error()
            }
        }
        catch {
            show_error()
            handleLogOut()
        }

    }
    useEffect(() => {
        setScraped(true)
    }, []);
    useEffect(() => {
        if (scraped) {
            // get data
            get_tickets()

        }
    }, [scraped]);
    return (

        <div className="scrollable" ref={scrollContainerRef}>
            <ToastContainer />
            <p className="text-xl">Ticket Logs</p>

            {(view === "ticket_log" || view === "ticket_edit") && (
                <div className="ticket-log-header">
                    <h1 className="ticket-log-title">{ticket?.channel_name}</h1>
                    <p className="ticket-log-info">Ticketinfo</p>
                    <p className="ticket-log-status">Status: <span className={`status-indicator ${ticket?.status === "CLOSED" ? "closed" : "open"}`}>{ticket?.status}</span></p>
                    <button onClick={go_back} className="btn_back">Back</button>
                </div>
            )}


            {view === "ticket_table" && (
                <div className="ticket_table">
                    <div className="ticket-table-container">
                        <table className="ticket-table">
                            <thead>
                                <tr className="ticket-table-row">
                                    <th className="ticket-table-header">Ticket</th>
                                    <th className="ticket-table-header">Status</th>
                                    <th className="ticket-table-header">Channel Name</th>
                                    <th className="ticket-table-header hide-on-mobile">Creation Date</th>
                                    <th className="ticket-table-header">Username</th>
                                    <th className="ticket-table-header hide-on-mobile">Guild Name</th>
                                    <th className="ticket-table-header">Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tickets.map((ticket, i) => (
                                    <tr className="ticket-table-row" key={ticket.id}>
                                        <td className="ticket-table-cell">{i}</td>
                                        <td className="ticket-table-cell">{ticket.status}</td>
                                        <td className="ticket-table-cell">
                                            {ticket.status === "OPEN" ?
                                                <a href={`https://discord.com/channels/${ticket.guild_id}/${ticket.channel_id}`} className="discord_link"><p>{ticket.channel_name}</p></a> :
                                                ticket.channel_name
                                            }
                                        </td>
                                        <td className="ticket-table-cell hide-on-mobile">{get_user_date(ticket.creation_date)}</td>
                                        <td className="ticket-table-cell">{ticket.user_name}</td>

                                        <td className="ticket-table-cell hide-on-mobile">{ticket.guild_name}</td>
                                        <td className="ticket-table-cell">
                                            <button
                                                className="ticket-action-button"
                                                onClick={() => ticket.status === "CLOSED" ? handle_log(ticket) : handle_edit(ticket)}>
                                                {ticket.status === "CLOSED" ? "Log" : "Edit"}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                </div>
            )}
            {view === "ticket_log" && (
                <>
                    <div className="chat-container">
                        {ticket_log && ticket_log.transcript.map((message, index) => {
                            const user = ticket_log.user_info[message.user_id];
                            console.log(user)
                            return (
                                <div key={index} className="message">
                                    <img src={user.avatar} alt={user.name} className="avatar" />
                                    <div className="message-info">
                                        <span className="username">{user.name}</span>
                                        <span className="timestamp">{new Date(message.timestamp).toLocaleString()}</span>
                                        <p className="content">{message.content}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
            {view === "ticket_edit" && (
                <div className="ticket_edit_container">
                    <p className="text-xl">Edit ticket</p>
                    <p>Name</p>
                    <input type="text" className="base_input" defaultValue={ticket?.channel_name} onChange={handle_ticket_change} />
                    <button className="btn_save" onClick={update_ticket} disabled={unlock_btn}><SaveIcon />Save</button>
                    <button className="btn_close" onClick={close_ticket}><DeleteIcon />Close Ticket</button>

                </div>
            )}

        </div>

    );
};

export default Tickets;