import React from "react";

interface CustomCommandsProps {
    // companyName: string;
}

const CustomCommands: React.FC<CustomCommandsProps> = () => {
    return (
        <p>soon.</p>
    );
};

export default CustomCommands;