import React, { useState } from 'react';
import Select, { components } from 'react-select';
import DiscordDropdown from './DiscordDropdown';
import "../../styles/dropdown_panel_styles.css"



interface DropdownProps {
    title: string;
    thumbnail_url: string;
    banner_url: string;
    placeholder: string;
    fields: FieldProps[];
    options: OptionPropsAr[];
    name: string;
    image: string;
    hex: string;
    description: string,
    footer_icon: string;
    footer_text: string;

}
interface FieldProps {
    name: string;
    value: string;
    inline: boolean;
}
interface OptionPropsAr {
    name: string;
    description: string;
    emoji: string;
}




const DropdownPanel: React.FC<DropdownProps> = ({ title, thumbnail_url, placeholder, fields, options, name, image, hex, footer_icon, footer_text, banner_url, description }) => {
    const searchable = false
    const [space, set_space] = useState(false);
    // fields = [
    //     { name: 'Field 1', value: 'Field value', inline: false },
    //     { name: 'Field 2', value: 'You can use custom emojis', inline: false },
    //     { name: 'Inline field 1', value: 'Fields can be inline', inline: true },
    //     { name: 'Inline field 2', value: 'Lorem ipsum', inline: true },
    //     { name: 'Inline field 3', value: 'value', inline: true },
    //     { name: 'Another field', value: 'Nope, didn\'t forget about this', inline: false }
    // ];
    return (
        <div className="embed" style={{ borderColor: hex }}>
            <div className="embedGrid">
                {/* <div className="embedAuthor embedMargin" style={{ display: 'flex' }}>
                    <img className="embedAuthorIcon embedAuthorLink" src="https://cdn.discordapp.com/embed/avatars/0.png" alt="Author" />
                    <p className="embedAuthorName" >Author name</p>
                </div> */}

                <div className="embedTitle embedMargin" style={{ display: 'unset' }}>
                    {title}
                </div>
                <div className="embedDescription embedMargin">
                    {description}
                </div>
                <div className="embedFields grid grid-cols-1 md:grid-cols-12 gap-4">
                    {fields.map((field, index) => (
                        <div
                            key={index}
                            className={`embedField ${field.inline ? 'md:col-span-4' : 'md:col-span-12'}`}
                        >
                            <div className="embedFieldName">{field.name}</div>
                            <div className="embedFieldValue">{field.value}</div>
                        </div>
                    ))}
                </div>
                {banner_url && banner_url !== "" &&
                    (
                        <div className="imageWrapper embedMedia embedImage" style={{ display: 'block' }}>
                            <img className="img embedImageLink" src={banner_url} alt="Banner" />
                        </div>
                    )}

                {thumbnail_url && thumbnail_url !== "" && (
                    <div className="imageWrapper embedThumbnail" style={{ display: 'block' }}>
                        <img className="img embedThumbnailLink" src={thumbnail_url} alt="Thumbnail" />
                    </div>
                )}

                <div className="embedFooter embedMargin" style={{ display: 'flex' }}>
                    <img className="embedFooterIcon embedFooterLink" src={footer_icon} alt="Footer Icon" />
                    <span className="embedFooterText">
                        {footer_text} | Ticket System
                    </span>
                </div>
            </div>
            <DiscordDropdown options={options} placeholder={placeholder} />

        </div >
    );

}
export default DropdownPanel