import React, { useState } from 'react';
import "../../styles/update_styles.css"
import axios from 'axios';

interface Field {
    name: string;
    value: string;
}

interface Update {
    version: string;
    fields: Field[];
}


interface UpdateProps {
    updates: Update[];
}
const API_URL = "https://api.solvextools.com"


const UpdatePage: React.FC = () => {
    const [getData, setGetData] = React.useState(false);
    const [updates, setUpdates] = useState<Update[]>([]);

    const get_update_data = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/user/info/bot/updates`, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });
            setUpdates(response.data)
        } catch (error) {
            console.error('Error fetching updates', error);
        }
    };


    React.useEffect(() => {
        if (getData) {
            get_update_data()
        }
    }, [getData]);

    React.useEffect(() => {
        setGetData(true)
    }, []);

    return (
        <div className="updatePage-container">
            {updates.map((update, index) => (
                <div key={index} className="updatePage-update">
                    <div className="updatePage-title">{`Update v ${update.version}`}</div>
                    {update.fields.map((field, i) => (
                        <div key={i} className="updatePage-field" style={{ borderBottom: i < update.fields.length - 1 ? '1px solid #4A4E54' : 'none' }}>
                            <div className="updatePage-fieldName">{field.name}</div>
                            <div className="updatePage-fieldValue">{field.value}</div>
                        </div>
                    ))}
                    <div className="updatePage-footer">
                        <img src="http://dashboard.solvextools.com/logo.png" alt="Footer Icon" className="updatePage-footerIcon" />
                        <div>Solvex Tools | Updates</div>
                    </div>
                </div>
            ))}
        </div>
    );


};


export default UpdatePage;
