import React, { useState } from "react";
import "../../styles/settings_styles.css"
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ReactDOM from 'react-dom';






import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = "https://api.solvextools.com";


interface BotData {
    id: number,
    name: string,
    image: string,
    hex_color: string,
    footer_text: string,
    footer_icon: string,
    app_id: string
}
interface Server {
    label: string;
    value: string;
}

interface Props {
    bot_data: BotData;
    handleUpdateBot: () => void;
    handleLogOut: () => void;
    handleBlur: () => void;
}

const select_styles = {
    input: (provided, state) => ({
        ...provided,
        color: "white",
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: '#384454',
        color: '#ffffff',
        height: "2.8rem"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#384454',
        color: '#ffffff',
    }),
    option: (provided) => ({
        ...provided,
        color: '#ffffff', // Example colors for text
    }),
}




const Settings: React.FC<Props> = ({ bot_data, handleUpdateBot, handleLogOut, handleBlur }) => {
    const [image, set_image] = useState<string>(bot_data["image"]);
    const [footer_text, set_footer_text] = useState<string>(bot_data["footer_text"]);
    const [footer_icon, set_footer_icon] = useState<string>(bot_data["footer_icon"]);
    const [hex, set_hex] = useState<string>(bot_data["hex_color"]);
    const [name, set_name] = useState<string>(bot_data["name"]);
    const [btn_disabled, set_btn_disabled] = useState<boolean>(true);

    const [show_add_server, set_show_add_server] = useState<boolean>(false)
    const [new_server_id, set_new_server_id] = useState('');


    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transformedServers, setTransformedServers] = useState(
        Array.isArray(bot_data["whitelisted_servers"])
            ? bot_data["whitelisted_servers"].map(server => ({ label: server, value: server }))
            : []
    );

    const handle_remove_server = (label) => {
        console.log(label)
        setTransformedServers(transformedServers.filter(option => option.value !== label));
        set_btn_disabled(false)


    }
    const handle_add_server = () => {
        console.log("clicked on new server add")
        set_show_add_server(!show_add_server)
        handleBlur()
    }
    const handleAdd = () => {
        console.log('Server ID:', new_server_id);
        set_show_add_server(false);
        if (new_server_id !== "") {
            setTransformedServers(prevServers => [...prevServers, { label: new_server_id, value: new_server_id }]);
        }
        handleBlur()
        set_new_server_id('');
        set_btn_disabled(false)
    };

    const handleCancel = () => {
        set_show_add_server(false); // Close the popup
        handleBlur()
    };

    const CustomOption = ({ innerProps, label }) => (
        <div className="mx-3 my-2">
            <div {...innerProps} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}

            >

                <span>{label}</span>
                <div className="remove_server" onClick={() => handle_remove_server(label)}>
                    <CloseIcon style={{ color: '#ff0000' }} />
                </div>

            </div>
        </div>
    );

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_name(event.target.value);
        if (errorMessage === null) {
            set_btn_disabled(false);
        }

    };
    const handleFooterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_footer_text(event.target.value);
        if (errorMessage === null) {
            set_btn_disabled(false);
        }
    };
    const handleFooterIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_footer_icon(event.target.value);
        if (errorMessage === null) {
            set_btn_disabled(false);
        }

    };
    const handleHexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_hex(event.target.value);
        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;

        if (hexRegex.test(hex)) {
            setErrorMessage(null); // Clear any previous error message
        } else {
            set_btn_disabled(true);
            return
        }

        if (errorMessage === null) {
            set_btn_disabled(false);
        }

    };
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_image(event.target.value);
        if (errorMessage === null) {
            set_btn_disabled(false);
        }

    };
    const show_success = () => toast.success('Successfully updated Bot! Use /update to update footer etc.', {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const show_pending = () => toast.promise(
        resolveAfter5Sec,
        {
            pending: 'Your bot image and name will be updated as fast as possible!',
        }
    )

    const resolveAfter5Sec = new Promise(resolve => setTimeout(resolve, 5000));




    const update_bot = async () => {
        // check for new data
        try {


            const response = await axios.post(`${API_URL}/v1/update/bot`, {
                name: name,
                image_url: image,
                hex_color: hex,
                footer_text: footer_text,
                footer_icon: footer_icon,
                app_id: bot_data.app_id,
                whitelisted_servers: transformedServers.map((server_data: Server) => server_data.value)

            }, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                set_btn_disabled(true)
                handleUpdateBot();

                show_success()
            }
            else if (response.status === 206) {
                show_pending();
            }
        }
        catch {
            handleLogOut()
        }


    }
    const check_values = () => {
        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;

        if (!hexRegex.test(hex)) {
            setErrorMessage("Invalid hex color value");
            return
        }
        else if (!name || !image || !hex || !footer_text || !footer_icon) {
            setErrorMessage("All fields must have a valid value.");
            return;
        }

        setErrorMessage(null); // Clear any previous error message

        update_bot()
    }


    return (
        <div className="container_scroll">
            <ToastContainer />
            <p className="text-xl">Bot Settings</p>
            {show_add_server && ReactDOM.createPortal(<div className="add_server_popup">
                <label>
                    New Server ID:
                    <input required type="text" value={new_server_id} onChange={(e) => set_new_server_id(e.target.value)} />
                </label>
                <button className="add" onClick={handleAdd}>Add</button>
                <button className="cancel" onClick={handleCancel}>Cancel</button>
            </div>, document.body // This is the container where the portal will be rendered
            )}
            <div className="grid gap-4 grid-cols-2 responsive content">

                <div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="bot_name" className="base_input_text">Name
                                <div className="info-container">
                                    <span className="ml-1 rounded-full inline-flex items-center justify-center border border-gray-400 w-3 h-3 info info-name">
                                        <span className="text-black font-bold text-l">i</span>
                                    </span>

                                    <span className="info-tooltip">Please be aware that modifications to the "Name" and "Image" fields might not reflect instantaneously.</span>                                </div>


                            </label>
                            <input type="text" id="bot_name"
                                className="base_input"
                                onChange={handleNameChange}
                                defaultValue={bot_data["name"]}
                                placeholder="Toolbox" required />
                        </div>
                        <div>
                            <label htmlFor="bot_image"
                                className="base_input_text">Image

                                <div className="info-container">
                                    <span className="ml-1 rounded-full inline-flex items-center justify-center border border-gray-400 w-3 h-3 info info-name">
                                        <span className="text-black font-bold text-l">i</span>
                                    </span>

                                    <span className="info-tooltip">Please be aware that modifications to the "Name" and "Image" fields might not reflect instantaneously.</span>
                                </div>

                            </label>

                            <input type="text" id="bot_image"
                                className="base_input"
                                defaultValue={bot_data["image"]}
                                onChange={handleImageChange}
                                placeholder="Icon" required />
                        </div>
                        <div>
                            <label htmlFor="embedColor" className="base_input_text">
                                Embed Hex Color
                            </label>
                            <div className="flex space-x-1">
                                <input
                                    type="text"
                                    id="bot_hex"
                                    name="bot_hex"
                                    value={hex}
                                    defaultValue={bot_data["hex_color"]}
                                    className="base_input flex"
                                    onChange={handleHexChange}
                                    required
                                />

                                <input
                                    value={hex}
                                    type="color"
                                    name="hex_color_input"
                                    className="base_input colorPicker flex"
                                    onChange={handleHexChange}
                                    required
                                />
                            </div>

                        </div>
                        <div>
                            <label htmlFor="footer_text"
                                className="base_input_text">Footer Text</label>
                            <input type="text" id="footer_text"
                                className="base_input"
                                defaultValue={bot_data["footer_text"]}
                                onChange={handleFooterTextChange}
                                placeholder="Text" required />
                        </div>
                        <div>
                            <label htmlFor="footer_icon"
                                className="base_input_text">Footer Icon</label>
                            <input type="text" id="footer_icon"
                                className="base_input"
                                defaultValue={bot_data["footer_icon"]}
                                onChange={handleFooterIconChange}
                                placeholder="Icon" required />
                        </div>
                        <div>
                            <label htmlFor="whitelisted_servers"
                                className="base_input_text">Whitelisted Servers</label>
                            <div className="flex space-x-1">
                                <Select
                                    className="server_dropdown mb-2"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    options={transformedServers}
                                    styles={select_styles}
                                    isOptionDisabled={() => true}

                                    // onChange={handle_change_server}
                                    components={{ Option: CustomOption }}
                                    placeholder=""
                                />
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="add_server"
                                        onClick={handle_add_server}

                                    >
                                        <AddIcon />
                                    </Button>

                                </div>

                            </div>
                        </div>
                    </div>
                    <button type="submit"
                        className="submit_btn text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce-once ml-px rounded-md"
                        disabled={btn_disabled}
                        onClick={check_values}
                    >
                        Save all
                    </button >
                    {errorMessage && <div className="error">{errorMessage}</div>}

                </div>
                <div>
                    <div className="preview">

                        <div className="bot_name">
                            <div className="flex items-center preview_embed" >
                                <img src={image} alt="bot img" className="rounded-full h-12 mr-2" />
                                <div className="flex flex-col">
                                    <p className="text-xs">
                                        <span>{name}</span>
                                        <span className="relative top-[-0.1rem] min-h-[1.275em] max-h-[1.275em] my-[0.075em] mx-[0.25rem] 
                          rounded-[3px] bot text-[rgb(255, 255, 255)] text-[0.625em] font-medium leading-[1.3] uppercase">Bot </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper_settings">

                            <div className="side-colored" style={{ backgroundColor: hex }}></div>
                            <div className="card embed settings_embed">
                                <div className="card-block">
                                    <div className="embed-inner"><div className="embed-title">Example</div><div className="fields">
                                        <div className="field undefined">
                                            <div className="field-name">Test name</div>
                                            <div className="field-value">Test Value</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>


                                <div className="space">
                                    <div className="footer">
                                        {footer_icon !== "" ?
                                            <div className="icon">
                                                <img src={footer_icon} alt="Footer Icon" className="footer_image" />
                                            </div>
                                            : null}
                                        <div className="footer_text">
                                            {footer_text}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

};

export default Settings;