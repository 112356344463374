import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Callback from "./pages/Callback";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import React Bootstrap CSS
import './styles/styles.css'
import Home from "./pages/Home";

function App() {
  return (

    <div className='App'>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/" element={<Dashboard defaultItem={"settings"} />} />
          <Route path="/settings" element={<Dashboard defaultItem={"settings"} />} />
          <Route path="/commands" element={<Dashboard defaultItem={"commands"} />} />
          <Route path="/updates" element={<Dashboard defaultItem={"updates"} />} />
          <Route path="/payment" element={<Dashboard defaultItem={"payment"} />} />
          <Route path="/dashboard-access" element={<Dashboard defaultItem={"dashboard-access"} />} />

          <Route path="/callback" element={<Callback />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
