import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import BuildIcon from '@mui/icons-material/Build';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import axios from "axios";






const API_URL = "https://api.solvextools.com";
// const API_URL = "http://127.0.0.1:5000"

interface ChildProps {
    onBotCreated: () => void;
    handleLogOut: () => void;
}


const Setup: React.FC<ChildProps> = ({ onBotCreated, handleLogOut }) => {
    const [setup, setStartSetup] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [requestSent, setRequestSent] = useState(false);
    const [page, setPage] = useState("default");


    const [name, setName] = useState<string>('Your Name');
    const [footer_text, setFooter_text] = useState<string>('FooterText');
    const [hex, setHex] = useState<string>('#1c2a97');
    const [footer_icon_url, setFooter_icon_url] = useState<string>('');
    const [image, setImage] = useState<string>('');

    const handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setImage(e.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Your validation and data handling logic here
        const formData = new FormData(event.currentTarget);
        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;


        const bot_name = formData.get("bot_name") as string;
        const bot_image = formData.get("bot_image") as string;
        const bot_hex = formData.get("bot_hex") as string;
        const bot_footer_text = formData.get("bot_footer_text") as string;
        const bot_footer_icon = formData.get("bot_footer_icon") as string;

        if (!bot_name || !bot_image || !bot_hex || !bot_footer_text) {
            setErrorMessage("All fields must have a valid value.");
            return;
        }
        else if (hexRegex.test(bot_hex)) {
            setErrorMessage(null); // Clear any previous error message
        } else {
            setErrorMessage("Invalid hex color value");
            return
        }
        setPage("pending")

        setErrorMessage(null);
        // 
        try {


            const response = await axios.post(`${API_URL}/v1/create/bot`, {
                name: bot_name,
                image_url: bot_image,
                hex_color: bot_hex,
                footer_text: bot_footer_text,
                footer_icon: bot_footer_icon,
            }, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                console.log("successfully set bot up")
            };
        }
        catch {
            handleLogOut()
        }

        // onBotCreated();

        // Proceed with your logic...
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const handleFooterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFooter_text(event.target.value);
    };
    const handleFooterIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFooter_icon_url(event.target.value);
    };
    const handleHexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHex(event.target.value);
    };
    useEffect(() => {
        setRequestSent(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (requestSent) {
                setStartSetup(true)
                const response = await axios.get(`${API_URL}/v1/user/info/bot`, {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    var has_bot = response.data.data.has_bot;
                    var pending = response.data.data.bot_pending;
                    if (has_bot && pending) {
                        // display pending page
                        setPage("pending")
                    }
                    else if (!has_bot) {
                        // display setup page
                        setPage("start")
                    }
                    else {
                        setPage("default");
                    }
                }
            };

        }
        fetchData()
    }, [requestSent]);



    if (page === "default") {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        )
    }
    else if (page === "setup") {
        return (
            <div className="text-white m-5 container_scroll">
                <p className="text-3xl font-bold mb-4 text-white">Bot-Setup</p>
                <div className="grid gap-4 grid-cols-2 responsive content">
                    <div>
                        <form onSubmit={handleSubmit} className="space-y-2">
                            <div>
                                <label htmlFor="name" className="base_input_text">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="bot_name"
                                    id="bot_name"
                                    // value={""} // Replace with the state variable containing the image link
                                    onChange={handleNameChange}
                                    className="base_input setup"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="imageLink" className="base_input_text">
                                    Image URL
                                </label>
                                <input
                                    type="text"
                                    id="bot_image"
                                    name="bot_image"
                                    onChange={handleLinkChange}
                                    className="base_input setup"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="embedColor" className="base_input_text">
                                    Embed Hex Color
                                </label>
                                <div className="flex space-x-10 hex">
                                    <input
                                        type="text"
                                        id="bot_hex"
                                        name="bot_hex"
                                        value={hex}
                                        className="base_input flex"
                                        onChange={handleHexChange}
                                        required
                                    />

                                    <input
                                        value={hex}
                                        type="color"
                                        name="hex_color_input"
                                        className="base_input colorPicker flex"
                                        onChange={handleHexChange}
                                        required
                                    />
                                </div>

                            </div>
                            <div>
                                <label htmlFor="footerText" className="base_input_text">
                                    Footer-Text
                                </label>
                                <input
                                    type="text"
                                    id="bot_footer_text"
                                    name="bot_footer_text"

                                    // value={""} // Replace with the state variable containing the image link
                                    onChange={handleFooterTextChange}
                                    className="base_input setup"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="footerText" className="base_input_text">
                                    Footer-Icon
                                </label>
                                <input
                                    type="text"
                                    id="bot_footer_icon"
                                    name="bot_footer_icon"
                                    // value={""} // Replace with the state variable containing the image link
                                    onChange={handleFooterIconChange}
                                    className="base_input setup"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="mt-2 bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600"
                            // onClick={createButtonClick}
                            >
                                Create Bot
                            </button>
                            {errorMessage && <div className="error">{errorMessage}</div>}

                        </form>
                    </div>
                    <div>
                        <div className="preview">

                            <div className="bot_name">
                                <div className="flex items-center" style={{ left: "152px", position: "absolute", top: "15px" }}>
                                    <img src={image} alt="bot img" className="rounded-full h-12 mr-2" /> {/* Adjust the height and width as needed */}
                                    <div className="flex flex-col"> {/* Create a new flex container for the name and Discord embed */}
                                        <p className="text-xs">
                                            <span>{name}</span>
                                            <span className="relative top-[-0.1rem] min-h-[1.275em] max-h-[1.275em] my-[0.075em] mx-[0.25rem] 
                          rounded-[3px] bot text-[rgb(255, 255, 255)] text-[0.625em] font-medium leading-[1.3] uppercase">Bot </span>
                                        </p>
                                    </div>
                                </div>
                            </div>




                            <div className="wrapper">

                                <div className="side-colored" style={{ backgroundColor: hex }}></div>
                                <div className="card embed setup-embed">
                                    <div className="card-block">
                                        <div className="embed-inner"><div className="embed-title">Example</div><div className="fields">
                                            <div className="field undefined">
                                                <div className="field-name">Test name</div>
                                                <div className="field-value">Test Value</div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>


                                    <div className="space">
                                        <div className="footer">
                                            {footer_icon_url !== "" ?
                                                <div className="icon">
                                                    <img src={footer_icon_url} alt="Footer Icon" className="footer_image" />
                                                </div>
                                                : null}
                                            <div className="footer_text">
                                                {footer_text}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (page === "pending") {
        return (
            <>
                <div className="flex items-center justify-center min-h-screen">
                    <div className="flex flex-col items-center justify-center m-10  rounded-lg shadow-lg p-8">
                        <h1 className="text-3xl font-bold mb-4 text-white">Hey {localStorage.getItem("name")}!</h1>
                        <p className="text-lg text-gray-600 mb-8">
                            Your bot is being processed and will take approximately 15 minutes. You will receive a private message on Discord once it's finished!
                        </p>
                        <button
                            type="submit"
                            className="bg-indigo-500 text-white py-3 px-6 rounded-md hover:bg-indigo-600 transition-colors duration-300"
                            onClick={() => handleLogOut()}
                        >
                            Log out
                        </button>
                    </div>
                </div>
            </>

        )
    }
    else if (page === "start") {
        return (
            <>
                <div className="flex items-center justify-center min-h-screen">
                    <div className="flex flex-col items-center justify-center m-10  rounded-lg shadow-lg p-8">
                        <h1 className="text-3xl font-bold mb-4 text-white">Hey {localStorage.getItem("name")}!</h1>
                        <p className="text-lg text-gray-600 mb-8">
                            Seems like you don't have a Bot yet! Click below to create one!
                        </p>
                        <button
                            type="submit"
                            className="bg-indigo-500 text-white py-3 px-6 rounded-md hover:bg-indigo-600 transition-colors duration-300"
                            onClick={() => setPage("setup")}
                        >
                            Start Setup
                        </button>
                    </div>
                </div>
            </>

        )
    }
    else {
        return (
            // 
            <>
                <div className="flex items-center justify-center min-h-screen">
                    <div className="flex flex-col items-center justify-center m-10  rounded-lg shadow-lg p-8">
                        <h1 className="text-3xl font-bold mb-4 text-white">Hey {localStorage.getItem("name")}!</h1>
                        <p className="text-lg text-gray-600 mb-8">
                            Seems like you don't have a Bot yet! Click below to create one!
                        </p>
                        <button
                            type="submit"
                            className="bg-indigo-500 text-white py-3 px-6 rounded-md hover:bg-indigo-600 transition-colors duration-300"
                            onClick={() => setPage("setup")}
                        >
                            Start Setup
                        </button>
                    </div>
                </div>
            </>
        )
    }




};

export default Setup;